
import './App.css';
import Curtain from './curtain';
import Home from './home';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

const App = () => (
 // <Router basename={process.env.PUBLIC_URL}>
 <Router>
      <Switch>
        <Route exact path="/" component={Curtain}/>
        <Route exact path="/home"component={Home}/>
      </Switch>

  </Router>
);

export default App;
