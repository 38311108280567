import React, { Component } from "react";
import './home.scss';
import twitter from './img/Twitter.svg';
import instagram from './img/instagram.svg';
import gitlab from './img/gitlab.svg';
import copyright from './img/copyright.svg';
import Calathea from './img/calathea.svg';
import CalatheaMobile from './img/CalatheaMobile.svg';
import PLANTDATA from './plant.json';
import Autocompleteinput from './autocompleteinput.js';
import logo from './img/toxic.svg';

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = { isMobile: false, show: false, searchTerm: "", showPlantSearch: false, inputVal: '', showPop: false };
    }


    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({
                isMobile: window.innerWidth < 1200
            });
        }, false);
    }

    onSubmit = () => {
        this.props.history.push('/');
    }

    open = () => {
        this.setState({ show: true })
    }
    close = () => {
        this.setState({ show: false })
    }

    checkplant = () => {
        this.setState({ showPop: true })
    }

    closeplant = () => {
        this.setState({ showPop: false })
    }
    showSearch = () => {
        this.setState({ showPlantSearch: true })
    }
    hideSearch = () => {
        this.setState({ showPlantSearch: false })
    }

    handleCallback = (childData) => {
        this.setState({ inputVal: childData })
    }




    render() {
        const aboutPop = this.state.show ? "AboutPopin__Wrapper show" : "AboutPopin__Wrapper";
        const imgSrc = this.state.isMobile ? CalatheaMobile : Calathea;
        const arr = PLANTDATA.plants.reduce((acc, val) => [...acc, val.name], []);
        const inputVal = this.state.inputVal;
        const showPlant = this.state.showPop && inputVal !== '' ? "AboutPopin__Wrapper show" : "AboutPopin__Wrapper";
        const element = PLANTDATA.plants.find(Element => Element.name.includes(inputVal));
        let logoPet ="logoPet";
        let textPet = "This plant is safe for you pets ";
        
        if(element !== null){
            if (element.isToxic) {
            logoPet = "logoPet showLogo";
            textPet = "This plant is not safe for you pets, some indigestion or intoxication may occure. If you already have it, make sure to keep it out of reach ";
            }
        }

        return (


            <div className="wrapper">
                <header className="Header__Wrapper">
                    <h1 className="logo-header">Green for <br></br> pets</h1>
                    <button className="about_button" onClick={this.open}>About</button>
                    <button className="about_button" onClick={this.onSubmit}>Home</button>
                </header>
                <div className="container">
                    <div className="search-container">
                        <h1 className="search_greeting">
                            Check if your plant is pet friendly?
                        </h1>
                        <Autocompleteinput suggestions={arr} parentCallback={this.handleCallback} />
                        <button className="check_button" onClick={this.checkplant}>Check</button>
                    </div>

                    <footer>
                        <ul className="sharing">
                            <li>
                                <img src={instagram} alt='logo' />
                            </li>
                            <li>
                                <img src={twitter} alt='logo'/>
                            </li>
                            <li>
                                <a href="https://gitlab.com/ninou007/greenforcats">
                                <img src={gitlab} alt='logo' />
                                </a>
                            </li>
                        </ul>
                        <div className="copyright">
                            <img src={copyright} alt='logo' />
                            <span id="copyright">Hanaa Taif</span>
                        </div>
                    </footer>
                </div>
                <aside className={aboutPop}>
                    <div className="AboutPopin__Inner">
                        <button className="CloseButton__Button" onClick={this.close}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 20" width="19" height="20"><g fill="none" stroke="#000"><path d="M2.3 1.8l15 16"></path><path d="M17.3 1.8l-15 16"></path></g></svg>
                        </button>
                        <div className="AboutPopin__Content"><h2 className="logo-header modal">GREEN FOR PETS</h2>
                            <p className="AboutPopin__P">One day , i went to HEMA "Dutsch brand" to buy some decore, i found a stuning plant Calathea see the picture below. The problem is that i have cats, so before every plant shopping i should check if the green is not toxic for my fluffy pets. I literraly spent 10 min to find the information, obviously as a developer first thing i thinked about is to create a simple website to search if a plant is Toxic or not for your pets. Hope you like it. The design of the website and the drawing are made by me, you can check the project on gitlab. </p>
                            <img id="monstera" alt='monstera' />

                        </div>

                    </div>
                </aside>
                <aside className={showPlant}>
                    <div className="AboutPlant__Inner">
                        <button className="CloseButton__Button" onClick={this.closeplant}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 20" width="19" height="20"><g fill="none" stroke="#000"><path d="M2.3 1.8l15 16"></path><path d="M17.3 1.8l-15 16"></path></g></svg>
                        </button>
                        <div className="AboutPlant">
                            <img src={element.url} id="imgPlant" alt='logo' />
                            <div className="columnDesc">
                                <h2> {element.name} </h2>
                                <img src={logo} className={logoPet} alt='logo' />
                                <span>{textPet} </span>
                            </div>
                        </div>

                    </div>

                </aside>
            </div>

        );
    }



}


export default Home;