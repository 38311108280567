import React, { Component } from "react";
import './curtain.scss';



class Curtain extends Component {

    constructor(props) {
        super(props);
        this.state = { isMobile: false, isVanish: false };
    }

    toggleClass = () => {
        this.setState({ isVanish: true })
    }

    
    onSubmit = () => {
        this.props.history.push('/home/');
    }
    clickEvent = () => {
        this.toggleClass();
        this.onSubmit();
    }
    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({
                isMobile: window.innerWidth < 1200
            });
        }, false);
    }

    render() {

        const containClass = this.state.isMobile ? "rainbow-container-mobile" : "rainbow-container";
        const vanish = this.state.isVanish ? "vanish" : "";

        return (

            <div id="primary" className="landing-wrapper">
                <h1 id="landing-logo" className={vanish} onClick={this.clickEvent}>Green for <br/>pets</h1>
                <div className={[containClass, vanish].join(" ")}>
                </div>
            </div>

        );
    }



}


export default Curtain;